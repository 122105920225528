import React from "react";
import "./style/main.scss";
import Header from "../../header/common/Header";
import Demo from "../../../pages/demo/Demo";
import {redirect, Route, Router, Navigate, Routes} from "react-router-dom";
import Search from "../../../components/searches/Search";
import MainMetricsPart from "../main_metrics/MainMetricsPart";
import Logout from "../../../pages/logout/Logout";
import Calendar from "../calendar/common/Calendar";
import NewsPage from "../../../pages/news/NewsPage";
import TradingPointsPage from "../../../pages/trading_points/TradingPointsPage";
import Education from "../../../pages/education/common/Education";
import {ApiContext} from "../../../domains/api/contexts/ApiContext";
import SideMenu from "../../side_menu/common/SideMenu";
import ApiProvider from "../../../domains/api/providers/ApiProvider";
import StatusColumn from "../calendar/common/Calendar";
import LoginWindow from "../../../domains/login/LoginWindow";
import EmployeesPage from "../../../pages/employees/EmployeesPage";
import Documents from "../../../pages/documents/Documents";
import Dialogs from "../../../pages/dialogs_page/DialogsPage";
import Check from "../../../pages/inspections/Check";

export default class Main extends React.Component<any, any> {
  render() {
    return <div className={"application"} data-testid={"application-container"}>
      <ApiProvider>
        <ApiContext.Consumer>
          {
            ({api, user}) => {
              if (user) return <SideMenu user={user} api={api!!}/>
              return <></>
            }
          }
        </ApiContext.Consumer>
      </ApiProvider>

        <main>

        <Header/>
        <span className={"hide-xs"}><Search/></span>
        <ApiProvider>
        <ApiContext.Consumer>
          {
            ({api, user}) => {
              if (user) return <MainMetricsPart api={api!!}/>
              return <></>
            }
          }
        </ApiContext.Consumer>
        </ApiProvider>
        <Routes>
          <Route path="/"
                  index={true}
                  element={<TradingPointsPage />}
          />
          <Route path="/demo/*"
                  index={true}
                  element={<Demo />}
          />

          <Route path="/education/*"
                 index={true}
                 element={<Education />}
          />
          <Route path="/check/*"
                 index={true}
                 element={<Check />}
          />
          <Route path="/documents/*"
                 index={true}
                 element={<Documents />}
          />
          <Route path="/news/*"
                  index={true}
                  element={<NewsPage />}
          />
          <Route path="/trading_points/*"
                 index={true}
                 element={<TradingPointsPage />}
          />
          <Route path="/companies/*"
                 index={true}
                 element={<TradingPointsPage />}
          />
          <Route path="/employees/*"
                 index={true}
                 element={<EmployeesPage />}
          />
          <Route path="/dialogs/*"
                 index={true}
                 element={<Dialogs />}
          />
          <Route path="/logout/*"
                 index={true}
                 element={<Logout />}
          />
        </Routes>
      </main>
      <ApiProvider>
        <ApiContext.Consumer>
          {
            ({api, user}) => {
              if (user) return <StatusColumn user={user} api={api!!}/>
              return <></>
            }
          }
        </ApiContext.Consumer>
      </ApiProvider>
      <ApiProvider>
        <ApiContext.Consumer>
          {
            ({api, user}) => {
              if (user) return <></>
              return <LoginWindow api={api!!} />
            }
          }
        </ApiContext.Consumer>
      </ApiProvider>
    </div>
  }
}