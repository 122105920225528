import React from "react";
import "./style/main_metrics.scss";
import Marquee from "react-fast-marquee";
import SmallMainMetricWidget from "../../../components/metric_widgets/small_main_metric_widget/SmallMainMetricWidget";
import {ApiEngine} from "api-engine";

const total = 50_165 * 2.7;
const sebes = 12_165 * 2.7;
const royalty = (total - sebes) * 1.1 * 7.5 / 100;

interface MainMetricsPartProps {
    api: ApiEngine
}

const metrics = [
    {value: total, title: "Товарооборот"},
    {value: sebes, title: "Себестоимость"},
    {value: royalty, title: "Роялти"},
]

export default class MainMetricsPart extends React.Component<MainMetricsPartProps, any> {
    constructor(props: MainMetricsPartProps) {
        super(props);

        this.state = {
            metrics: []
        }
    }

    getMetrics() {
        const me = this;
        const metricsUrl = `/api/partners/general_metrics`;
        me.props.api.asyncFetch(metricsUrl, {}).then((res: any) => {
            // alert(JSON.stringify(res));
            me.setState({metrics: res.metricsList});
        });
    }

    componentDidMount() {
        this.getMetrics();
    }
    
    render() {
        const me = this;
        const usedMetrics = me.state.metrics.map((_metric: any, _metricIndex: any) => {
            return <span style={{display: "block", paddingLeft: "30px"}}>
                    <SmallMainMetricWidget key={`metric_${_metricIndex}`}
                                           metric={_metric}
                                           api={me.props.api}
                                           />
                </span>
        })
        // if (window.innerWidth < 500 ) {
        //     return <Marquee
        //         pauseOnHover
        //         play={true}
        //         gradient={true}
        //         gradientColor={"var(--blue-gray)"}
        //         className={"main-metrics"}
        //     >
        //     {/*<div className={"main-metrics"}>*/}
        //         { usedMetrics}
        //     {/*</div>*/}
        //     </Marquee>
        // }
        return <div className={"main-metrics"}>{usedMetrics}</div>;
    }
}