import React from "react";
import { ApiContext } from "../../domains/api/contexts/ApiContext";
import ApiProvider from "../../domains/api/providers/ApiProvider";
import InspectionsList from "./InspectionsList";

export default class Check extends React.Component<any, any> {
    render() {
        return <ApiProvider>
            <ApiContext.Consumer>
              {
                ({api, user}) => {
                  return <InspectionsList api={api!!} user={user!!} />
                }
              }
            </ApiContext.Consumer>
        </ApiProvider>
    }
}
