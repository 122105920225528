import React from "react";
import "./style/tickets_view.scss";
import { ApiEngine } from "api-engine";

interface KanbanedTicketsProps {
    tickets: any[];
    api: ApiEngine;
    realm: string;
}

const stages = {
    "В работе": 50,
    "Открыт": 10,
    "Закрыт": 100
} as any;

export default class KanbanedTickets extends React.Component<KanbanedTicketsProps, any> {
    render() {
        const me = this;

        return <div className={"kanbaned-tickets"}>
            {me.props.tickets.map((ticket) => (
                <div className={"kanbaned-ticket"}>
                    <div className={"kanbaned-ticket-title"}>{ticket.title}</div>
                    <div className={"process-line"}>
                        <div className={"process-line-item"} style={{width: `${stages[ticket.stage as keyof typeof stages]}%`}}></div>
                    </div>
                    <br/> 
                    <p className={"kanbaned-ticket-status"}><span className={"kanbaned-ticket-status-label"}>Прогресс:</span> <b>{stages[ticket.stage as keyof typeof stages]}%</b></p>
                    <p className={"kanbaned-ticket-status"}><span className={"kanbaned-ticket-status-label"}>Текущий статус:</span> <b>{ticket.stage}</b></p>
                </div>
            ))}
        </div>;
    }
}