import React from "react";
import {ApiEngine} from "api-engine";
import "./style/education.scss";
import ModalWindow from "../../../layout/modal/ModalWindow";

interface EducationPageContentProps {
    api: ApiEngine;
}

interface EducationPageContentState {
    knowledgeNodes: any[];
    shownKnowledgeNodes: any[];
    openedKnowledgeNode: any;
    filterValue: string;
}

export default class EducationPageContent extends React.Component<EducationPageContentProps, EducationPageContentState> {
    
    constructor(props: EducationPageContentProps) {
        super(props);

        this.state = {
            knowledgeNodes: [],
            shownKnowledgeNodes: [],
            openedKnowledgeNode: null,
            filterValue: ""
        }
    }
    
    componentDidMount() {
        const me = this;
        me.props.api.asyncFetch("/api/education/", {})
            .then((response: any) => {
                me.setState({
                    knowledgeNodes: response.nodes,
                    shownKnowledgeNodes: response.nodes
                });
            });
    }

    filterKnowledgeNodes() {
        const me = this;
        me.setState({
            shownKnowledgeNodes: me.state.knowledgeNodes.filter((node: any) => JSON.stringify(node).toLowerCase().includes(me.state.filterValue.toLowerCase()))
        });
    }
    
    render() {
        const me = this;
        return <div className="education-page">
            <h1>База знаний</h1>
            <div className={"filtering"}>
                <input placeholder="Фильтр"
                    value={me.state.filterValue}
                    onChange={(e) => {
                        me.setState({filterValue: e.target.value}, () => {
                            me.filterKnowledgeNodes();
                        });
                    }}
                />
                <i className="fal fa-filter" />
            </div>
            <div className={"education-buttons"}>
                {
                    me.state.shownKnowledgeNodes.map(
                        (node: any) => {
                            return <div key={node.id} className={"article-button"}>
                                <h2>{node.title}</h2>
                                <p className="annotation">{node.annotation}</p>
                                <p className="learn-more"
                                    onClick={() => {
                                        me.setState({openedKnowledgeNode: node});
                                    }}
                                >Узнать больше</p>
                                {/* {JSON.stringify(node)} */}
                                </div>
                        }
                    )
                }
            </div>
            { me.state.openedKnowledgeNode &&
            <ModalWindow
                onClick={() => {
                    me.setState({openedKnowledgeNode: null})
                }}
            >
                <div className="knowledge-node-content modal-shadow"
                     onClick={(e) => e.stopPropagation()}>
                    <h1>{me.state.openedKnowledgeNode.title}</h1>
                    <div dangerouslySetInnerHTML={{__html: me.state.openedKnowledgeNode.content}}/>
                </div>      
            </ModalWindow>
            }
        </div>
    }
}