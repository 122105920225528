import React from "react";
import { ApiEngine } from "api-engine";
import "./style/documents.scss";

interface DocumentsPageProps {
    user: any;
    api: ApiEngine;
}   

interface DocumentsPageState {
    documents: any[];
    page: number;
    size: number;
}

const invoices = [
    {
        id: "12321aaa2212123dddddd",
        companyName: "ООО \"ВАН ГРУПП КОМПАНИ\"",
        type: "Счет на оплату за сентябрь 2024",
        date: new Date(),
        number: "1322-444-211"
    },
    {
        id: "12321aaa2212123dddd222",
        companyName: "ООО \"ВАН ГРУПП КОМПАНИ\"",
        type: "Счет на оплату за октябрь 2024",
        date: new Date(),
        number: "1322-444-211"
    },
    {
        id: "12221aaa2212123dddd222",
        companyName: "ООО \"ВАН ГРУПП КОМПАНИ\"",
        type: "Акт сверки за октябрь 2024",
        date: new Date(),
        number: "1322-444-011"
    }
]

export default class DocumentsPage extends React.Component<DocumentsPageProps, DocumentsPageState> {
    
    constructor(props: DocumentsPageProps) {
        super(props);
        this.state = {
            documents: [],
            page: 0,
            size: 20
        }
        this.getDocuments = this.getDocuments.bind(this);
        this.getMoreDocuments = this.getMoreDocuments.bind(this);
    }

    componentDidMount() {
        const me = this;
        me.getDocuments();
    }

    getDocuments() {
        const me = this;
        const url = `/api/documents?page=${me.state.page}&size=${me.state.size}`;
        me.props.api.asyncFetch(url, {}).then((res: any) => {
            me.setState({documents: [...invoices, ...res.documents]});
        });
    }

    getMoreDocuments() {
        const me = this;
        me.setState({page: me.state.page + 1}, () => {
            const url = `/api/documents?page=${me.state.page}&size=${me.state.size}`;
            me.props.api.asyncFetch(url, {}).then((res: any) => {
                if (res.documents) {
                    me.setState({documents: [...me.state.documents, ...res.documents]});
                }
            });
        });
    }

    render() {
        const me = this;
        return <div className={"documents-page"}>
            <h1>Документы</h1>
            {
                <div ref={(ref) => {
                    if (!ref) return;
                    const observer = new IntersectionObserver(
                        ([entry]) => {
                            if (entry.isIntersecting) {
                                me.getMoreDocuments();
                            }
                        },
                        { threshold: 1.0 }
                    );
                    observer.observe(ref);
                }}
                className={"documents"}
                >
                    {me.state.documents.map((document: any) => (
                        <div key={document.id} className={"document-item"}>
                            <div className={"document-item-header"}>
                                <h3 className={"document-type"}>{document.type}</h3>
                                <h5 className={"company-name"}>{document.companyName}</h5>
                            </div>
                            <div className={"document-item-content"}>
                                <i className={"fas fa-file-alt fa-2x"}>
                                </i>
                            </div>
                            <p className={"doc-number"}>№&nbsp;{document.number}</p>
                            <p className={"date"}>{new Date(document.date).toLocaleString()}</p>
                        </div>
                    ))}
                </div>
            }
        </div>
    }
}
