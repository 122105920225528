import React from "react";

interface BirthDaysProps {
    api: any;
}

interface BirthDaysState {
    birthdays: any[];
}

export default class BirthDays extends React.Component<BirthDaysProps, BirthDaysState> {
    constructor(props: BirthDaysProps) {
        super(props);
        this.state = {
            birthdays: []
        };
    }

    componentDidMount() {
        const me = this;
        me.props.api.asyncFetch("/api/employees/birthdays", {}).then((response: any) => {
            if (response.employeesNames) {
                me.setState({birthdays: response.employeesNames});
            }
        });
    }

    render() {
        const me = this;
        if (me.state.birthdays.length === 0) return null;
        return <div className={"birthdays"}>    
            <h3 className={"birthdays-title"}>Сегодня день рождения у:</h3>
            <div className={"birthdays-part"}>
                {me.state.birthdays.map((item) => {
                    return <div key={item} className={"birthday-item"}>
                        <p>{item}</p>
                    </div>
                })}
            </div>
        </div>
    }
}
