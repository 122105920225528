import { ApiEngine } from "api-engine";
import React from "react";
import "./style/news-page.scss";
import NewsArticle from "../../layout/header/news/news_articles/NewsArticle";
import ModalWindow from "../../layout/modal/ModalWindow";
import NewsItem from "./news_items/NewsItem";

interface NewsPageContentProps {
    api: ApiEngine;
}

interface NewsPageContentState {
    news: any[];
    shownNewsIds: { [key: string]: boolean };
    activeNewsArticle: any | null;
    filter: string;
}

export default class NewsPageContent extends React.Component<NewsPageContentProps, NewsPageContentState> {
    constructor(props: NewsPageContentProps) {
        super(props);
        this.state = {
            news: [],
            shownNewsIds: {},
            activeNewsArticle: null,
            filter: ""
        };
    }

    componentDidMount() {
        const url = "/api/news/index";

        this.props.api.asyncFetch(url, {}).then((response) => {
            const res = response.news_articles;
            this.setState({ news: res });
        });
    }

    render() {
        const me = this;

        return <>
            <div className="news-page-content">
                <h1>Новостная лента</h1>
                <div className={"search-bar"}>
                    <input type="text" placeholder="Фильтр" value={me.state.filter} onChange={(e) => me.setState({
                        filter: e.target.value,
                        // showTradingPoints: me.state.tradingPoints.filter((tradingPoint) => tradingPoint.title.toLowerCase().includes(e.target.value.toLowerCase()))
                    })}/>
                </div>
                <div className="news-list">
                    {this.state.news.filter((x) => {
                        if (me.state.filter.startsWith("#")) {
                            let res = x.keywords.toString()
                                .split(";").map((x: string) => {
                                    return x.toLowerCase().trim();
                                });
                            console.log(res);
                            let filter = me.state.filter.toLowerCase().trim();
                            console.log(filter)
                            console.log(res.indexOf(filter))
                            return res.indexOf(filter.slice(1,1000000)) > -1;
                        }
                        return JSON.stringify(x).toLowerCase().indexOf(me.state.filter.toLowerCase()) !== -1;
                    }).map((news, index) => {
                        return <NewsItem api={this.props.api}
                                        news={news}
                                        key={index}
                                        filterCallback={(filter) => {
                                                me.setState({filter: `#${filter.trim()}`});
                                        }}
                                />

                    })}

                </div>

            </div>
            {me.state.activeNewsArticle && <ModalWindow
                onClick={() => {
                    me.setState({activeNewsArticle: null})
                }}
            >
                <NewsArticle newsArticle={me.state.activeNewsArticle} api={me.props.api}/>
            </ModalWindow>}
        </>;
    }
}

