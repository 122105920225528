import React from "react";
import "./style/inspections.scss";
import { ApiEngine } from "api-engine";
import User from "../../domains/actors/users/User";
import CircularDial from "../../components/metric_widgets/dials/circular/CircularDial";

interface InspectionsListProps {    
    api: ApiEngine;
    user: User;
}

interface InspectionsListState {
    listOfInspections: any[];
    typesOfPatterns: any[];
    activePatternType: string | null;
}

export default class InspectionsList extends React.Component<InspectionsListProps, any> {
    constructor(props: InspectionsListProps) {
        super(props);
        this.state = {
            listOfInspections: [],
            typesOfPatterns: [],
            activePatternType: null
        }

        this.getListOfInspections = this.getListOfInspections.bind(this);
    }

    componentDidMount() {
        const me = this;
        me.getListOfInspections().then(() => {
            me.extractTypesOfPatterns();
        });
    }

    async getListOfInspections() {
        const url = "/api/trading_point_inspections/";
        const me = this;
        return new Promise((resolve, reject) => {   
            me.props.api.asyncFetch(url, {})
            .then((response: any) => {
                if (response.inspections) {
                    me.setState({listOfInspections: response.inspections}, () => {
                        resolve(true);
                    });
                } else {
                    reject(false);
                }
            });
        });
    }

    extractTypesOfPatterns() {
        const me = this;
        const listOfInspections = me.state.listOfInspections;
        const typesOfPatterns = listOfInspections
            .map((inspection: any) => inspection.pattern.name)
            .filter((name: string, index: number, array: string[]) => array.indexOf(name) === index);
        me.setState({typesOfPatterns: typesOfPatterns.sort()});
    }
    
    render() {
        const me = this;
        return <>
            <h1>Проверки ({me.state.listOfInspections.length})</h1>
            <br/>
            <div className="inspections-filters">
                <p>Вид проверки</p>
                <button className={me.state.activePatternType === null ? "active" : ""} onClick={() => me.setState({activePatternType: null})}>Все</button>
                {me.state.typesOfPatterns.map((type: any,  index: number) => {
                    return <button 
                        className={me.state.activePatternType === type ? "active" : ""}
                        key={`${type}-${index}-inspections`}
                        onClick={() => me.setState({activePatternType: type})}>{type}</button>
                })}
            </div>
            <div className="inspections">
                {
                    me.state.listOfInspections.map((inspection: any) => {
                        if (me.state.activePatternType !== null && inspection.pattern.name !== me.state.activePatternType) {
                            return null;
                        }
                        if (!inspection.company) {
                            return null;
                        }
                        return <div className="inspection-item" 
                                    key={inspection.id}
                                    style={
                                        {
                                            background: `linear-gradient(-135deg, #${inspection.pattern.color} 0%, var(--black) 2%, var(--black) 100%)`,
                                            borderLeft: `4px solid #${inspection.pattern.color}`
                                        }
                                    }
                        >
                            <div className="inspection-item__info">
                                <p className="inspection-item__info__pattern">{inspection.data.pattern.name}</p>
                                <p className="inspection-item__info__company">{inspection.company?.title}</p>
                                <div className="inspection-item__info__dates">
                                    <i className="fas fa-calendar-alt" />
                                    <p className="inspection-item__dates__started-at">{new Date(inspection.startedAt).toLocaleString()}</p>
                                    <p className="inspection-item__dates__finished-at">{new Date(inspection.finishedAt).toLocaleString()}</p>
                                </div>
                            </div>
                            { inspection.maxRate > 0 && <CircularDial value={inspection.factRate} maxValue={inspection.maxRate} /> }
                            {/* {JSON.stringify(inspection)} */}
                        </div>
                    })
                }
            </div>
        </>
    }
}
