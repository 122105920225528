import React from "react";
import "./style/story-square.scss";
import {ApiEngine} from "api-engine";

interface StorySquareProps {
  content: any;
  onClick: any;
  api: ApiEngine
}

interface StorySquareState {
  wasOpened: boolean
}

export default class StorySquareComponent extends React.Component<StorySquareProps, StorySquareState> {
  constructor(_props: StorySquareProps) {
    super(_props);
    this.state = {
      wasOpened: false
    }
  }

  render() {
    const me = this;
    return <div onClick={() => {
      me.setState({
        wasOpened: true
      },me.props.onClick );
    }} data-testid={"story-square"} className={"story-square"}
                style={me.state.wasOpened ? {filter: "brightness(0.7)"} : {border: "2px solid var(--green-500)"} }
    >
      { me.props.content.thumbnail_url &&
        <img

              src={`${me.props.api.serverUrl}/${me.props.content.thumbnail_url}`}
             alt={me.props.content.title} />
      }
      <p className={"title"}>{me.props.content.title}</p>
      <svg className={"back-shadow"} xmlns="http://www.w3.org/2000/svg" width="186" height="71" viewBox="0 0 186 71" fill="none">
        <g filter="url(#filter0_f_2301_136)">
          <ellipse cx="93" cy="48" rx="63" ry="17.5" fill="#282828"/>
        </g>
        <defs>
          <filter id="filter0_f_2301_136" x="0" y="0.5" width="186" height="95" filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_2301_136"/>
          </filter>
        </defs>
      </svg>
    </div>
  }
}