import React from "react";
import "./style/news-article.scss";
import NewsItem from "../../../../pages/news/news_items/NewsItem";
import {ApiEngine} from "api-engine";

interface NewsArticleProps {
  newsArticle: any;
  api: ApiEngine
}

export default class NewsArticle extends React.Component<NewsArticleProps, any> {
  render() {
    const me = this;
    return <div className={"news-article modal-shadow"} onClick={(e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
    }}>
      {/*<h1>{me.props.newsArticle.title}</h1>*/}
      {/*<h2>{me.props.newsArticle.lead}</h2>*/}
      {/*<div dangerouslySetInnerHTML={{__html: me.props.newsArticle.body}}></div>*/}

      <NewsItem api={this.props.api}
                news={me.props.newsArticle}
                filterCallback={(filter) => {
                }}
      />
    </div>
  }
}