import React from "react";
import "./style/small-main-metric-widget.scss";
import { ApiEngine } from "api-engine";
import CircularDial from "../dials/circular/CircularDial";

interface SmallMainMetricWidgetProps {
    metric: any
    api: ApiEngine
}

interface SmallMainMetricWidgetState {  
    dateInterval: string,
    dateStart: Date,
    dateEnd: Date,
    value: number | null,
    targetValue: number | null,
    lessIsBetter: boolean
}

export default class SmallMainMetricWidget extends React.Component<SmallMainMetricWidgetProps, SmallMainMetricWidgetState> {
    constructor(props: SmallMainMetricWidgetProps) {
        super(props);

        this.state = {
            dateInterval: "day",
            dateStart: new Date(new Date().setDate(new Date().getDate() - 3)),
            dateEnd: new Date(),
            value: null,
            targetValue: null,
            lessIsBetter: false
        }

        this.getNewData = this.getNewData.bind(this);
    }

    componentDidMount() {
        this.getNewData();
    }

    getNewData() {
        const me = this;
        let metricsUrl = `/api/partners/general_metrics/get?metric=`;
        metricsUrl += encodeURI(me.props.metric.title);
        if (me.state.dateStart) {
            console.log('dateStart type:', typeof me.state.dateStart);
            metricsUrl += "&date_start=" + new Date(me.state.dateStart).toISOString();
        }
        if (me.state.dateEnd) {
            metricsUrl += "&date_end=" + new Date(me.state.dateEnd).toISOString();
        }
        me.props.api.asyncFetch(metricsUrl, {}).then((res: any) => {
            // alert(JSON.stringify(res));
            me.setState({value: res.value[res.value.length - 1].value, targetValue: res.targetValue, lessIsBetter: res.lessIsBetter});
        });
    }


    render() {
        const me = this;
        return <div className={"small-main-metric-widget"}>
            { me.state.targetValue != undefined && me.state.value != undefined && 
                <CircularDial 
                    value={me.state.value || 0} 
                    maxValue={me.state.value > me.state.targetValue ? me.state.value : me.state.targetValue} 
                    lessIsBetter={me.state.lessIsBetter}
                /> 
            }
            <h1 className={"caption"}>{me.props.metric.title}</h1>
            {/* <h2 className={"value"}>{me.state.value?.toLocaleString()} / {me.state.targetValue?.toLocaleString()}</h2> */}
        </div>;
    }
}