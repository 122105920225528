import React from "react";
import "./style/full-metric-widget.scss";
import LineChart from "../../charts/line_chart/LineChart";
import { ApiEngine } from "api-engine";

interface FullMetricWidgetProps {
    tradingPoint: any,
    metric: any,
    api: ApiEngine,
}

interface FullMetricWidgetState {
    dateInterval: DateInterval,
    dateStart: Date,
    dateEnd: Date,
    data: any[]
    networkData: any[]
}

interface DateInterval {
    title: string,
    value: string,
    dateStart: Date,
    dateEnd: Date
}


const possibleDateIntervals = [{
    title:"10дн",
    value: "10d"    ,
    dateStart: new Date(new Date().setDate(new Date().getDate() - 10)),
    dateEnd: new Date()
},
// {
//     title:"Сегодня",
//     value: "1d",
//     dateStart: new Date().setHours(0, 0, 0, 0),
//     dateEnd: new Date()
// },
    {
    title:"неделя",
    value: "7d",
    dateStart: new Date().setDate(new Date().getDate() - 7),
    dateEnd: new Date()
}, {
    title:"месяц",
    value: "31d",
    dateStart: new Date().setDate(new Date().getDate() - 30),
    dateEnd: new Date()
}, {
    title:"1кв",
    value: "92d",
    dateStart: new Date(new Date().getFullYear() - 1, 11, 30),
    dateEnd: new Date(new Date().getFullYear(), 2, 31)
}, {
    title:"2кв",
    value: "92d",
    dateStart: new Date(new Date().getFullYear(), 3, 1),
    dateEnd: new Date(new Date().getFullYear(), 5, 30)
}, {
    title:"3кв",
    value: "92d",
    dateStart: new Date(new Date().getFullYear(), 6, 1),
    dateEnd: new Date(new Date().getFullYear(), 8, 30)
}, {
    title:"4кв",
    value: "92d",
    dateStart: new Date(new Date().getFullYear(), 9, 1),
    dateEnd: new Date(new Date().getFullYear(), 11, 31)
},
// {
//     title:"год",
//     value: "365d",
//     dateStart: new Date().setFullYear(new Date().getFullYear() - 1, 0, 1),
//     dateEnd: new Date().setFullYear(new Date().getFullYear(), 0, 1)
// }
] as DateInterval[];

export default class FullMetricWidget extends React.Component<FullMetricWidgetProps, FullMetricWidgetState> {
    constructor(props: FullMetricWidgetProps) {
        super(props);
        this.state = {
            dateInterval: possibleDateIntervals[0],
            dateStart: possibleDateIntervals[0].dateStart,
            dateEnd: possibleDateIntervals[0].dateEnd,
            data: this.props.metric.data,
            networkData: [],
            // data: []
        }

        console.log(this.props.metric.data);

        this.getNewData = this.getNewData.bind(this);
        this.getFullData = this.getFullData.bind(this);
    }

    componentDidMount() {
        const me = this;
        me.getFullData();
    }

    getFullData() {
        const me = this;
        me.getNewData(this.props.tradingPoint).then((res: any[]) => {
            me.setState({
                data: res
            }, () => {
                if (!me.props.metric.showNetworkValue) return;
                me.getNewData(null).then((res: any[]) => {
                    me.setState({networkData: res})
                });
            })
        },() => {
            me.setState({
                data: []
            })
        });
    }

    async getNewData(tradingPoint: any): Promise<any> {
        const me = this;
        let metricsUrl = `/api/companies/metrics/get?metric=`;
        if (tradingPoint)
            metricsUrl = `/api/companies/${me.props.tradingPoint?.id}/metrics/get?metric=`;
        metricsUrl += encodeURI(me.props.metric.title);
        if (me.state.dateStart) {
            console.log('dateStart type:', typeof me.state.dateStart);
            metricsUrl += "&date_start=" + new Date(me.state.dateStart).toISOString();
        }
        if (me.state.dateEnd) {
            metricsUrl += "&date_end=" + new Date(me.state.dateEnd).toISOString();
        }
        return new Promise((_resolve, _reject) => {
            me.props.api.asyncFetch(metricsUrl, {}).then((res: any) => {
                let value = [] as any[];
                if (res.value) {
                    value = res.value.map((x: any) => {
                        return {
                            label: new Date(x.label),
                            value: x.value
                        }
                    });
                    _resolve(value);
                } else {
                    _reject(false);
                }
            })
        })
    }

    render() {
        const me = this;
        return <div className={"full-metric-widget"}>
            <h1 className={"caption"}>{me.props.metric.title}</h1>
            <h2 className={"value"}>{me.props.metric.value.toLocaleString()}</h2>
            <div className={"date-interval"}>
                {possibleDateIntervals.map((interval, index) => (
                    <button type="button"
                            key={index}
                            onClick={() => me.setState({    dateInterval: interval,
                                                            dateStart: interval.dateStart,
                                                            dateEnd: interval.dateEnd
                                                        }, () => me.getFullData())}
                            className={me.state.dateInterval.title === interval.title ? "active" : ""}
                    >
                        {interval.title}
                    </button>
                ))}
                <input type={"date"}
                       value={me.state.dateStart.toISOString().split('T')[0]}
                       onChange={(e) => me.setState({dateStart: new Date(e.target.value)}, () => me.getFullData())}
                />
                <input type={"date"}
                       value={me.state.dateEnd.toISOString().split('T')[0]}
                       onChange={(e) => me.setState({dateEnd: new Date(e.target.value)}, () => me.getFullData())}
                />
            </div>
            {me.state.data.length > 0 ?
            <LineChart key={`${me.state.dateStart}-${me.state.dateEnd}-${me.state.data.length}-${me.state.networkData.length}`}
                       data={[
                           me.state.data,
                           me.state.networkData
                        ]} width={"100%"} showX={true} showY={true} />
            :
            <p>Нет данных</p>
            }
        </div>
    }
}